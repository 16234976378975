/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import helpers section **/

/** Import component section **/
import FooterView from './FooterView';

export const FooterComponent = () => {
  return <FooterView />;
};

export default FooterComponent;

import React from 'react';

/** Import component section **/
import HomeView from './HomeView';

function HomeComponent() {
  return <HomeView />;
}

export default HomeComponent;

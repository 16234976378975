import request from '../api-connector';

export const contactService = ({ name, email, message }) => {
  const serviceDef = {
    url: 'contact-mail',
    method: 'post',
    params: { name, email, message }
  };
  return request(serviceDef);
};

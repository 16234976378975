/*********************************************************************
 ***********************  ROUTES DEFINITION  *************************
 *********************************************************************/
const currentDomain = window.location.origin;
// Public routes
export const ABOUT_ROUTE = '/';
export const CERTIFICATES_ROUTE = '/certificates';
export const CONTACT_ROUTE = '/contact';

export const OUTSTANDINGS_URL = currentDomain + '/clients/outstandings';
// 'clients/outstandings';
export const SUPPLIERS_URL = currentDomain + '/clients/suppliers';
export const MANAGERS_URL = currentDomain + '/clients/managers';

// Private routes

/** Import react/libraries section **/
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

/** Import helpers section **/
import { PUBLIC_ROUTES } from '../../config/ROUTES_CONTENT';

/** Import styles section **/
import './MainStyles.scss';

/** Import resources section **/

/** Import component section **/
import Header from '../Header';
import Footer from '../Footer';

const MainComponent = () => {
  return (
    <div className="main">
      <Router>
        <div className="main_header">
          <Header />
        </div>
        <div className="main_content" id="page-wrap">
          <Switch>
            {PUBLIC_ROUTES.map((route, index) => (
              <Route
                exact
                key={index}
                path={route.path}
                component={route.component}
              />
            ))}
            <Redirect to="/" />
          </Switch>
        </div>
        <div className="main_footer">
          <Footer />
        </div>
      </Router>
    </div>
  );
};

export default MainComponent;

import React, { useState } from 'react';

/** Import services section **/
import { contactService } from '../../api/services/contact-services';

/** Import component section **/
import ContactView from './ContactView';

const initContactData = {
  name: '',
  email: '',
  message: ''
};

function ContactComponent() {
  const [data, setData] = useState(initContactData);

  const sendEmail = () => {
    contactService(data)
      .then((response) => {
        window.alert('Mensaje enviado!');
        setData(initContactData);
      })
      .catch((error) => {
        window.alert('Error al enviar el mensaje');
        console.error('send email error: ', error);
      });
  };

  const handleOnInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const { name, value } = target;
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    sendEmail();
  };

  return (
    <ContactView
      data={data}
      onInputChange={handleOnInputChange}
      onSubmit={handleOnSubmit}
    />
  );
}

export default ContactComponent;

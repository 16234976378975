/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/
import fbIcon from '../../assets/images/footer_fb.png';
import mailIcon from '../../assets/images/footer_mail.png';
import whatsappIcon from '../../assets/images/footer_whatsapp.png';
import youtubeIcon from '../../assets/images/footer_youtube.png';

/** Import styles section **/
import './FooterStyles.scss';

/** Import helpers section **/

/** Import component section **/

export const FooterView = ({ menuOpen, onMenuClick }) => {
  return (
    <div className="mainfooter">
      <div className="mainfooter_social">
        <a href="https://wa.me/5214611690867">
          <div className="mainfooter_social-footericon">
            <img src={whatsappIcon} alt="whatsapp" />
          </div>
        </a>
        <a href="mailto:info@fidtail.org">
          <div className="mainfooter_social-footericon">
            <img src={mailIcon} alt="mail" />
          </div>
        </a>
        <div
          className="mainfooter_social-footericon"
          onClick={() =>
            window.open(
              'https://www.facebook.com/Fidtail-155067595214095',
              '_blank'
            )
          }
        >
          <img src={fbIcon} alt="facebook" />
        </div>
        <div
          className="mainfooter_social-footericon"
          onClick={() =>
            window.open(
              'https://www.youtube.com/channel/UCLvZ6yxikdsJB3qa8u2L4QA',
              '_blank'
            )
          }
        >
          <img src={youtubeIcon} alt="youtube" />
        </div>
      </div>
      <span>Copyright © Fidtail 2021</span>
    </div>
  );
};

export default FooterView;

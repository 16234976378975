import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/

/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/
const ABOUT_MENU = 'about';
const CERTIFICATES_MENU = 'certificates';
const CONTACT_MENU = 'contact';
const OUTSTANDINGS_MENU = 'outstandings';
const SUPPLIERS_MENU = 'suppliers';
const MANAGERS_MENU = 'managers';

/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
  {
    id: ABOUT_MENU,
    label: 'Quiénes somos',
    url: routes.ABOUT_ROUTE
  },
  {
    id: CERTIFICATES_MENU,
    label: 'Buscador mundial de detalladores certificados',
    url: routes.CERTIFICATES_ROUTE
  },
  {
    id: CONTACT_MENU,
    label: 'Contacto',
    url: routes.CONTACT_ROUTE
  },
  {
    id: OUTSTANDINGS_MENU,
    label: 'Alumnos destacados',
    url: routes.OUTSTANDINGS_URL,
    external: true
  },
  {
    id: SUPPLIERS_MENU,
    label: 'Proveedores',
    url: routes.SUPPLIERS_URL,
    external: true
  },
  {
    id: MANAGERS_MENU,
    label: 'Directores',
    url: routes.MANAGERS_URL,
    external: true
  }
];
export default MENU_ITEMS;

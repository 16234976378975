import React, { useState, useEffect, useCallback } from 'react';

/** Import services section **/
import { getCertificatesByCountriesService } from '../../api/services/certificates-services';

/** Import helpers section **/

/** Import component section **/
import CertificatesView from './CertificatesView';

const ROTATION_SPEED = 1000;

function CertificatesComponent() {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [inRotation, setInRotation] = useState(false);

  const handleOnResetCountry = () => {
    setSelectedCountry(null);
  };

  const getCertificatesByCountries = useCallback(() => {
    getCertificatesByCountriesService()
      .then((response) => {
        const countriesArr = response?.countries;
        if (countriesArr) setCountries(countriesArr);
      })
      .catch((error) => {
        console.error('getCountries error: ', error);
      });
  }, []);

  useEffect(() => {
    getCertificatesByCountries();
  }, [getCertificatesByCountries]);

  const handleOnMenuClick = (country) => {
    setInRotation(true);
    setSelectedCountry(country);
    setTimeout(() => {
      setInRotation(false);
    }, ROTATION_SPEED);
  };

  const handleOnCountrySelect = (e) => {
    const countryId = parseInt(e.target.value);
    const country = countries.find((item) => item.id === countryId);
    if (country) handleOnMenuClick(country);
  };

  const handleOnPointClick = (id) => {
    const found = countries.find((item) => item.id === id);
    if (found) {
      handleOnMenuClick(found);
    }
  };

  return (
    <CertificatesView
      data={countries}
      selectedCountry={selectedCountry}
      inRotation={inRotation}
      onPointClick={handleOnPointClick}
      onGlobeClick={handleOnResetCountry}
      onMenuClick={handleOnMenuClick}
      onCountrySelect={handleOnCountrySelect}
    />
  );
}

export default CertificatesComponent;

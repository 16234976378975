/** Import helpers section **/
import * as routes from './URL_ROUTES';

/** Import component section **/
import HomeComponent from '../views/Home';
import CertificatesComponent from '../views/Certificates';
import ContactComponent from '../views/Contact';

/*********************************************************************
 ************************  ROUTES CONTENT  ***************************
 *********************************************************************/

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: HomeComponent
  },
  {
    path: routes.CERTIFICATES_ROUTE,
    component: CertificatesComponent
  },
  {
    path: routes.CONTACT_ROUTE,
    component: ContactComponent
  },
  {
    path: routes.OUTSTANDINGS_URL,
    component: null
  },
  {
    path: routes.SUPPLIERS_URL,
    component: null
  },
  {
    path: routes.MANAGERS_URL,
    component: null
  }
];

export const PRIVATE_ROUTES = [];

import React from 'react';

/** Import helpers section **/
import { T } from 'react-translator-component';

/** Import resources section **/
import logoMaster from '../../assets/images/logo_master.png';
import prizeIcon from '../../assets/images/prize.png';
import network from '../../assets/images/fidtail_network.png';
import home_welcome_photo from '../../assets/images/home_welcome_photo.jpg';

import certBar1 from '../../assets/images/certification_bar1.png';
import certBar2 from '../../assets/images/certification_bar2.png';
import certBar3 from '../../assets/images/certification_bar3.png';
import certBar4 from '../../assets/images/certification_bar4.png';

/** Import styles section **/
import './HomeStyles.scss';

function HomeView() {
  return (
    <div className="home">
      <div className="home_first">
        <div className="home_first_container">
          <div className="home_first_container_content">
            <div className="home_first_container_content_master">
              <img src={logoMaster} alt="master-logo" />
            </div>
            <div className="home_first_container_content_text">
              {T('Acredita tus conocimientos con la única')}
              <div className="g-bold">
                {T('certificación con respaldo internacional')}
              </div>
            </div>
            <div className="home_first_container_content_prize">
              <img src={prizeIcon} alt="prize-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="home_second">
        <div className="home_second_container">
          <div className="home_second_container_header">
            <div className="home_second_container_header_network">
              <img src={network} alt="network" />
            </div>
            <div className="home_second_container_header_text">
              <div className="g-bold">
                {T(
                  'Bienvenido a la red más grande de profesionales del detallado automotriz en el mundo.'
                )}
              </div>
              <div>
                {T(
                  'FIDTAIL ha impartido cientos de cursos acreditados sobre detallado automotriz PREMIUM, con miles de alumnos graduados en más de 16 países.'
                )}
              </div>
              <div>
                {T(
                  'Los centros de detallado automotriz internacionales están creciendo con rapidez y FIDTAIL está especialmente calificado para ayudarlos a avanzar profesionalmente y mejorar la técnica de calidad de cada trabajo.'
                )}
              </div>
            </div>
          </div>
          <div className="home_second_container_welcome-photo">
            <img src={home_welcome_photo} alt="welcome" />
          </div>
        </div>
      </div>
      <div className="home_third">
        <div className="home_third_container">
          <div className="home_third_container_header">
            <div className="home_third_container_header_master">
              <img src={logoMaster} alt="master-logo2" />
            </div>
            <h1>{T('Niveles de Certificación')}</h1>
          </div>
          <div className="home_third_container_bars">
            <div className="home_third_container_bars_bar">
              <img src={certBar1} alt="certBar1" />
            </div>
            <div className="home_third_container_bars_bar">
              <img src={certBar2} alt="certBar2" />
            </div>
            <div className="home_third_container_bars_bar">
              <img src={certBar3} alt="certBar3" />
            </div>
            <div className="home_third_container_bars_bar">
              <img src={certBar4} alt="certBar4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeView;
